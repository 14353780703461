import React from 'react';

const Logo = (props) => {
  if(props.color === 'dark'){
    return (
        <img
          alt="Logo"
          src="/static/logo.png"
          style={{ maxWidth: '230px' }}
          {...props}
        />
      );
  }else{
    return (
      <img
        alt="Logo"
        src="/static/logo-white.png"
        style={{ maxWidth: '230px' }}
        {...props}
      />
    );
  }
}

export default Logo;
