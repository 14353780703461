/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  // Link,
  List,
  ListSubheader,
  // Typography,
  makeStyles
} from '@material-ui/core';
import {
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  User as UserIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import DescriptionIcon from '@material-ui/icons/Description';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import useAuth from 'src/hooks/useAuth';

let sections = [];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

if(user.role === "Full Admin"){
  sections = [
    {
      subheader: 'General',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/'
        },
        {
          title: 'Insights',
          icon: BarChartIcon,
          href: '/insights'
        },
      ]
    },
    {
      subheader: 'Management',
      items: [
        {
          title: 'Users',
          icon: UsersIcon,
          href: '/vendors',
          items: [
            {
              title: 'Vendors list',
              href: '/vendors'
            },
            {
              title: 'Users list',
              href: '/users'
            },
          ]
        },
        {
          title: 'Companies',
          icon: FolderIcon,
          href: '/companies',
          items: [
            {
              title: 'All Companies',
              href: '/companies'
            },
          ]
        },
        {
          title: 'Marketing',
          href: '/banner',
          icon: HorizontalSplitIcon,
          items: [
            {
              title: 'Banner',
              href: '/banner'
            },
          ]
        },
        {
          title: 'Requests',
          icon: DescriptionIcon,
          href: '/requests',
          items: [
            {
              title: 'All Requests',
              href: '/requests'
            },
          ]
        },
        // {
        //   title: 'Posts',
        //   icon: DescriptionIcon,
        //   href: '/posts',
        //   items: [
        //     {
        //       title: 'All Posts',
        //       href: '/posts'
        //     },
        //   ]
        // },
      ]
    },
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Account',
          href: '/account',
          icon: UserIcon
        },
        {
          title: 'Access Control',
          href: '/access-control',
          icon: SupervisedUserCircleIcon
        },
      ]
    },
  ]
}

if(user.role === "Associate"){
  sections = [
    {
      subheader: 'General',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/'
        },
        {
          title: 'Insights',
          icon: BarChartIcon,
          href: '/insights'
        },
      ]
    },
    {
      subheader: 'Management',
      items: [
        {
          title: 'Users',
          icon: UsersIcon,
          href: '/vendors',
          items: [
            {
              title: 'Vendors list',
              href: '/vendors'
            },
            {
              title: 'Users list',
              href: '/users'
            },
          ]
        },
        {
          title: 'Companies',
          icon: FolderIcon,
          href: '/companies',
          items: [
            {
              title: 'All Companies',
              href: '/companies'
            },
          ]
        },
        {
          title: 'Marketing',
          href: '/banner',
          icon: HorizontalSplitIcon,
          items: [
            {
              title: 'Banner',
              href: '/banner'
            },
          ]
        },
        {
          title: 'Posts',
          icon: DescriptionIcon,
          href: '/posts',
          items: [
            {
              title: 'All Posts',
              href: '/posts'
            },
          ]
        },
      ]
    },
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Account',
          href: '/account',
          icon: UserIcon
        },
      ]
    },
  ]
}

if(user.role === "Analyst"){
  sections = [
    {
      subheader: 'General',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/'
        },
        {
          title: 'Insights',
          icon: BarChartIcon,
          href: '/insights'
        },
      ]
    },
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Account',
          href: '/account',
          icon: UserIcon
        },
      ]
    },
  ]
}

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
