export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  UNICORN: 'UNICORN',
  ONE_DARK: 'ONE_DARK',
};

export const BackendURL = "https://apigfmarketplace.online";
// export const BackendURL = "http://209.97.134.198";

const getReadableDate = (lastLogin) => {
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var d = new Date(lastLogin);
  var date = d.getDate();
  var month = months[d.getMonth()];
  var year = d.getFullYear();
  return date + " " + month + " " + year;
}
const getReadableDay = (lastLogin) => {
  var d = new Date(lastLogin);
  var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var day = days[d.getDay()];
  return day;
}

const getReadableTime = (date) => {
  var d = new Date(date);
  var hr = d.getHours();
  var min = d.getMinutes();
  if (min < 10) {
      min = "0" + min;
  }
  var ampm = "am";
  if(hr >= 12){
      ampm = "pm";
  }
  if(hr > 12){
      hr = hr - 12;
  }
  if(hr === 0){
      hr = 12;
  }
  return hr + ":" + min + ampm;
}
const convertUTCDateToLocalDate = (date) => {
  // console.log(date);
  date = new Date(date);
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate;   
}

export const getDate = (d) => { 
  if(!d){ return '--'; }
  let date = convertUTCDateToLocalDate(new Date(d));
  return ( getReadableDay(date) + ' ' +  getReadableDate(date) + ' at ' + getReadableTime(date) );
};

export const getHour  = (d) => {
  let date = convertUTCDateToLocalDate(new Date(d));
  return getReadableTime(date);
}
export const getDay  = (d) => {
  let date = convertUTCDateToLocalDate(new Date(d));
  var d = new Date(date);
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var month = months[d.getMonth()];
  return month + ' ' + d.getDate()+'th';
}

export const getMonth  = (d) => {
  let date = convertUTCDateToLocalDate(new Date(d));
  var d = new Date(date);
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var month = months[d.getMonth()];
  var year = d.getFullYear();
  return month + ' ' + year;
}

export const addCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
}