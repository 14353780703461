import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import GlobalAuthGuard from 'src/components/GlobalAuthGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GlobalAuthGuard,
    layout: DashboardLayout,
    path: '/',
    component: lazy(() => import('src/views/project/OverviewView'))
  },
  {
    exact: true,
    guard: GlobalAuthGuard,
    layout: DashboardLayout,
    path: '/insights',
    component: lazy(() => import('src/views/Insights/DashboardView'))
  },
  {
    exact: true,
    guard: GlobalAuthGuard,
    layout: DashboardLayout,
    path: '/app/account',
    component: () => <Redirect to="/" />
  },
  {
    exact: true,
    guard: GlobalAuthGuard,
    layout: DashboardLayout,
    path: '/account',
    component: lazy(() => import('src/views/account/AccountView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/data/collecting',
        component: lazy(() => import('src/views/DataCollecting'))
      },
      {
        exact: true,
        path: '/banner',
        component: lazy(() => import('src/views/BannerManagement'))
      },
      {
        exact: true,
        path: '/access-control',
        component: lazy(() => import('src/views/AccessControl/AdminsList'))
      },
      {
        exact: true,
        path: '/admin/new',
        component: lazy(() => import('src/views/AccessControl/NewAdmin'))
      },
      {
        exact: true,
        path: '/admin/:id',
        component: lazy(() => import('src/views/AccessControl/AdminEditView'))
      },
      {
        exact: true,
        path: '/posts',
        component: lazy(() => import('src/views/Posts/PostsView'))
      },
      {
        exact: true,
        path: '/companies',
        component: lazy(() => import('src/views/companies/CompaniesView'))
      },
      {
        exact: true,
        path: '/company/:id',
        component: lazy(() => import('src/views/companies/CompanyEdit'))
      },
      {
        exact: true,
        path: '/mail',
        component: () => <Redirect to="/mail/all" />
      },
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('src/views/Users/UsersListView'))
      },
      {
        exact: true,
        path: '/user/:customerId',
        component: lazy(() => import('src/views/Users/UserEditView'))
      },
      {
        exact: true,
        path: '/vendors',
        component: lazy(() => import('src/views/Vendors/VendorsListView'))
      },
      {
        exact: true,
        path: '/vendor/:VendorID',
        component: lazy(() => import('src/views/Vendors/VendorEditView'))
      },
      // Requests
      {
        exact: true,
        path: '/requests',
        component: lazy(() => import('src/views/AllRequests/RequestsListView'))
      },
      {
        exact: true,
        path: '/requests/:id',
        component: lazy(() => import('src/views/AllRequests/RequestView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
];

export default routes;
